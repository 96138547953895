import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import ProjectView from '../views/ProjectView.vue';
//import VueRouter from 'vue-router';
//import HomeView from '../components/HelloWorld.vue';

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            component: HomeView
        },
        {
          path: '/:language',
          children: [
            {
              path: '',
              component: HomeView
            },
            {
              path: 'apps',
              name: 'apps-main',
              component: ProjectView,
              //redirect: '/',
              children: [
                {
                  path: 'hue',
                  name: 'project-details',
                  component: ProjectView
                }
              ]
            },
          ]
        }
        
    ]
});


export default router;