import { createApp } from 'vue';
import router from './router/index.js';
import 'materialize-css/dist/css/materialize.min.css'
import App from './App.vue';
import {createI18n} from 'vue-i18n';
//import VueI18n from 'vue-i18n';


const messages = {
    en: require('./locales/en.json'),
    sv: require('./locales/sv.json')
}

var language = 'en';

for(let l of Object.keys(messages)) {
    if (window.navigator.language.startsWith(l)) {
        language = l;
    }
}
const i18n = createI18n({
    locale: language,
    messages
});

const app = createApp(App);
app.use(i18n);
app.use(router);

app.mount('#app');
