<template>
    <main-header :links="links"></main-header>
    <div class="content">
        <div class="header-video">
            <video autoplay muted loop>
                <source src="@/assets/video/SpaceForce.mp4" type="video/mp4">
                Your browser does not support the video tag.
            </video>
            <div class="header-content">
                <h1>Begagnade Rymdskepp</h1>
            
                <p>{{$t('tag_line')}}</p>


                <!--<img src="@/assets/BRAB_logo_transparent.png" alt="Begagnade Rymdskepp AB" />-->
            </div>
        </div>

        <section id="apps">
                <div class="project-banner row">
                    <div class="col m6 s12">
                        <router-link to="apps/hue"><img src="@/assets/hue-mr/screenshot-light.png" alt="Hue MR" /></router-link>
                    </div>
                    <div class="project-banner-content col m6 s12">
                        <h3>{{ $t('hue_mr_app.header') }}</h3>
                        <p>{{ $t('hue_mr_app.short_description') }}</p>

                        <router-link :to="$i18n.locale + '/apps/hue'" class="btn-large">{{ $t('read_more') }}</router-link>
                    </div>
                </div>
            
        </section>
        
        <section id="about">
            <div class="about-content">
                <h2>{{ $t("about_us.header" )}}</h2>
                <p>{{ $t('about_us.p_1') }}</p>
                <p>{{ $t('about_us.p_2') }}</p>
                <p>{{ $t('about_us.p_3') }}</p>
                <p>{{ $t('about_us.p_4') }}</p>
            </div>
        </section>


        <section id="contact">
            <h2>{{ $t('contact_form.header' )}}</h2>
            <contact-form></contact-form>
        </section>


    </div>
    
</template>
<script>
import MainHeader from '@/components/MainHeader.vue';
import ContactForm from '@/components/ContactForm.vue';

export default {
    components: {
        MainHeader,
        ContactForm
    },
    data () {
        return {
            links: [
                {href: '#', text:'menu.home'},
                {href: '#apps', text:'menu.apps'},
                {href: '#about', text:'menu.about'},
                {href: '#contact', text:'menu.contact'}
            ]
        }
    }
}

</script>
<style scoped>
section {
    margin: 50px 0;
}
.content {
    position: relative;
}

.header-video {
    position: relative;
    overflow: hidden;
    
}

.header-video video {
            right: 0;
            bottom: 0;
            max-height: 550px;
            min-height: 200px;
            width: 100%;
            height: auto;
}

@media (max-width: 768px) {

    .header-video {
      /*margin-left: -20px;
      margin-right: -20px;*/
      overflow: hidden;
    }

    .header-video video {
        transform: scale(1.0); /* Adjust the scale factor as needed */
        /*width: 150%;*/
    }

    .header-video h1 {
        font-size: 36pt;
    }
}

@media (max-width: 500px) {
    .header-video video {
        transform: scale(1.5); /* Adjust the scale factor as needed */
    }
}

.header-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
    color: #fff;

}

.header-content img {
    max-width: 75%;
}

.project-banner {
    background-color: #f7ebe5;
    border-radius: 25px;
    padding: 20px 20px 20px 20px;
    text-align: center;
    


    .btn-large {
        margin-top: 15px;
    }
}

.project-banner  img {
    width: 100%;
}


</style>
