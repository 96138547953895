<template>
  <div class="container">
    <RouterView />
    
  </div>
  <MainFooter />
  
</template>

<script>
import { RouterView } from 'vue-router';
import MainFooter from '@/components/MainFooter.vue';


export default {
  mounted () {
    document.title = "Begagnade Rymdskepp";
    let language = this.$route.params?.language;
    if (language) {
      this.$i18n.locale = language;
    } 
    
  },
  //props: ['language'],
  name: 'App',
  components: {
    RouterView,
    MainFooter
  },
  metaInfo: {
    title: 'Begagnade Rymdskepp',
    titleTemplate: null
  },
  watch: {
    '$i18n.locale'(newLocale, oldLocale) {
      console.log(`Going from ${oldLocale} to ${newLocale}`)
    }
  },
  data() {
    return {
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Arial', sans-serif;
}

body {
  margin: 0;
  padding: 0;
}

a {
  color: #ff5e5f;
}

nav {
  background-color: inherit;
  box-shadow: none;
}

.container {
  margin: 0px auto;
}

a.btn-large {
  color: white;
}

</style>
